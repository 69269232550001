import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/breadcrumbs/breadcrumbs.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/footer/Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/header/TopHeader.jsx");
